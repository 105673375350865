
import CreateBookingShipmentV1 from "../create-booking/add-shipment-id.vue";
import CreateBookingShipmentV2 from "../create-booking-v2/add-shipment-id.vue";
import { Options, Vue } from "vue-class-component";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";
import { BookingController } from "@/app/ui/controllers/BookingController";

@Options({
  components: {
    CreateBookingShipmentV1,
    CreateBookingShipmentV2
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
      return;
    }
    if (!BookingController.isBookingSuccess) {
      BookingController.onOpenClosePage(to);
      if (BookingController.answer) {
        BookingController.setAnswer(false);
        BookingController.clearShipmentIdList();
        BookingController.setIsEditTerminated(false);
        next();
      } else {
        next(false);
      }
    } else {
      BookingController.setIsEditTerminated(false);
      next();
    }
  }
})
export default class ConfigCreateShipmentBooking extends Vue {
  get isBookingVersion() {
    return FlagsPermissionBooking.permission_booking_version.getValue();
  }

  get isAbleBookingShipment() {
    return FlagsPermissionBooking.permission_booking_create_shipment_id.isEnabled();
  }

  get configBookingShipmentVersion() {
    let result = "";
    switch (this.isBookingVersion.toLowerCase()) {
      case "v1":
        result = "CreateBookingShipmentV1";
        break;
      case "v2":
        result = "CreateBookingShipmentV2";
        break;
      default:
        result = "CreateBookingShipmentV1";
        break;
    }

    if (this.isAbleBookingShipment) {
      return result;
    }
    return "";
  }
}

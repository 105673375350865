
import { Options, Vue, prop } from "vue-class-component";
import ButtonBooking from "@/app/ui/components/button-list/index.vue";
import generatePDFV2 from "@/app/ui/modules/receipt-v2/document";
import generateThermalPDFV2 from "@/app/ui/modules/receipt-v2/thermal";
import generateCommercialInvoice from "@/app/ui/modules/commercial-invoice/index";
import JsBarcode from "jsbarcode";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import ModalErrorBalance from "@/app/ui/views/shipment/booking/component/modal-error-balance.vue";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  payloadOfThermalData,
  downloadFilePrn
} from "@/app/infrastructures/misc/UtilsThermal";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import { ThermalReceiptData } from "@/domain/entities/Printer";
import {
  AddManualBooking,
  AddBookingCorporate
} from "@/data/payload/api/BookingRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { logoBlack } from "@/app/ui/modules/receipt-v2/constants/images";

interface Diskon {
  isDiscount: boolean;
  totalTariff: number;
  totalAfterDiscount: number;
  totalBeforeDiscount: number;
}

class Props {
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  bookingType = prop<string>({
    default: "",
    type: String
  });
  payload = prop<any>({
    default: {},
    type: Object
  });
  onBooking = prop<any>({
    type: Function
  });
  isDiscountActive = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits: ["setDiscount"],
  components: {
    ButtonBooking,
    ModalErrorBalance
  }
})
export default class BookV2 extends Vue.with(Props) {
  get isMigratingFromElexys() {
    return process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true";
  }

  get menus() {
    return [
      {
        title: this.$t("bookingShipment.bookButton.printLater"),
        icon: "check-circle-border"
      },
      {
        title: this.$t("bookingShipment.bookButton.printThermal"),
        icon: "printer"
      },
      {
        title: this.$t("bookingShipment.bookButton.printThermalPdf"),
        icon: "document-download-border"
      },
      {
        title: this.$t("bookingShipment.bookButton.printBasic"),
        icon: "document-download-border"
      }
    ];
  }

  statusGoodForClients = "ecommerce";
  get statusGoodValueForclients() {
    this.manualBooking.statusGoods = this.statusGoodForClients;
    return this.statusGoodForClients;
  }

  set statusGoodValueForclients(value: string) {
    this.statusGoodForClients = value;
  }
  get insuranceAsDefault() {
    this.manualBooking.insurance = this.insuranceForClients;
    return this.insuranceForClients;
  }

  defaultInsuranceTypeForClients = "free";
  set insuranceForClients(value: string) {
    this.defaultInsuranceTypeForClients = value;
  }

  get insuranceForClients() {
    return this.defaultInsuranceTypeForClients;
  }

  async action(index: number) {
    if (this.isBalanceUnderLimitForPos) {
      this.balanceUnderLimit = true;
      return;
    }
    if (this.onBooking && this.isMigratingFromElexys) {
      const resultData = await this.onBooking();
      if (!resultData) {
        return;
      }
    }
    dataLayer(
      `booking_bayar_${PaymentController.paymentMethod.name.toLowerCase()}_clicked`,
      {},
      ["userId", "userType", "timestamp"]
    );
    switch (index) {
      case 0:
        await this.bookingManual();
        dataLayer(
          this.bookingType === "client"
            ? "client_booking_print_nanti_button_clicked"
            : "booking_print_nanti_button_clicked",
          {},
          ["userId", "userType", "timestamp"]
        );
        break;
      case 1:
        await this.bookPrintThermal();
        dataLayer(
          this.bookingType === "client"
            ? "client_booking_print_thermal_button_clicked"
            : "booking_print_thermal_button_clicked",
          {},
          ["userId", "userType", "timestamp"]
        );
        break;
      case 2:
        await this.bookPrintDoc(0, true);
        dataLayer(
          this.bookingType === "client"
            ? "client_booking_print_thermal_pdf_button_clicked"
            : "booking_print_thermal_pdf_button_clicked",
          {},
          ["userId", "userType", "timestamp"]
        );
        break;
      default:
        await this.bookPrintDoc(1);
        dataLayer(
          this.bookingType === "client"
            ? "client_booking_print_basic_button_clicked"
            : "booking_print_basic_button_clicked",
          {},
          ["userId", "userType", "timestamp"]
        );
        break;
    }
  }

  get STTID() {
    return BookingController.sttId;
  }

  get manualBooking() {
    return BookingController.manualBooking;
  }

  get isBookingCorporate() {
    return this.$route.fullPath.includes("/create-client")
  }

  async bookingStt() {
    if (!BookingController.guardBookingSubmit) {
      BookingController.setGuardBookingSubmit(true);
      if (this.bookingType !== "client") {
        const book: any = await this.submitBookingAndPrintSavePdf();
        if (book?.isDiscount) {
          const params: Diskon = {
            isDiscount: book.isDiscount,
            totalTariff: book.totalDiscount + book.totalAfterDiscount,
            totalAfterDiscount: book.totalAfterDiscount,
            totalBeforeDiscount: book.totalBeforeDiscount
          };
          this.$emit("setDiscount", params);
        }

        return book?.success;
      } else {
        return BookingController.addBookingCorporate({
          payload: new AddBookingCorporate({
            sttClient: this.manualBooking.clientName.id,
            sttNo: this.payload.sttManual,
            sttShipmentId: "",
            sttNoRefExternal: this.payload.referenceExternal,
            sttTaxNumber: this.payload.taxNumber,
            sttGoodsEstimatePrice: this.payload.estimationPrice,
            sttGoodsStatus:
              this.payload.statusGoods || this.statusGoodValueForclients,
            sttOriginCityId: this.payload.originCity,
            sttOriginDistrictId: this.payload.originDistrict,
            sttDestinationCityId: this.payload.destinationCity,
            sttDestinationDistrictId: this.payload.destinationDistrict,
            sttSenderName: this.payload.sender,
            sttSenderPhone: this.payload.phoneSender,
            sttSenderAddress: this.payload.addressSender,
            sttRecipientName: this.payload.receiver,
            sttRecipientAddress: this.payload.addressReceiver,
            sttRecipientAddressType: this.payload.addressReceiverType,
            sttRecipientPhone: this.payload.phoneReceiver,
            sttInsuranceType: this.payload.insurance || this.insuranceAsDefault,
            sttProductType: this.payload.productName,
            sttCommodityCode: this.payload.commodityCode,
            sttClientSttId: 0,
            sttVendorSttId: 0,
            sttIsCod: this.payload.isCODClient,
            sttIsDfod: this.payload.isDFODClient,
            sttIsPad: this.payload.isPAD,
            sttIsWoodpacking: this.payload.isWoodpack,
            sttPieces: this.payload.sttPieces,
            sttPiecePerPack: this.payload.sttPiecePerPack,
            sttNextCommodity: this.payload.sttNextCommodity,
            sttCodAmount: this.payload.codAmount,
            postalCodeDestination: this.payload.postalCodeDestination,
            sttWeightAttachFiles: this.payload.sttWeightAttachFiles,
            sttRecipientEmail: this.payload.sttRecipientEmail,
            sttKtpImage: this.payload.sttKtpImage,
            sttTaxImage: this.payload.sttTaxImage,
            sttCommodityDetail: this.payload.sttCommodityDetail,
            sttAttachFiles: this.payload.sttAttachFiles,
            sttIdentityNumber: this.payload.sttIdentityNumber,
            sttInterTaxNumber: this.payload.sttInterTaxNumber
          }),
          isBookingClient: this.isBookingCorporate
        });
      }
    }
  }

  async submitBookingAndPrintSavePdf() {
    try {
      const createbooking: ResponsePayload = await BookingController.addManualBooking(
        this.payloadBookingManual
      );

      await BookingController.onSuccessBooking({
        sttNumber: createbooking.sttNumber,
        sttId: createbooking.sttId,
        chargeableWeight: createbooking.chargeableWeight
      });
      return createbooking;
    } catch (error) {
      BookingController.generateErrorBooking({
        err: error,
        payload: this.payloadBookingManual,
        errorFor: "book",
        calllFunctionAgain: this.submitBookingAndPrintSavePdf
      });
      return new ResponsePayload({
        success: false
      });
    } finally {
      BookingController.finalCreateBooking();
    }
  }

  get payloadBookingManual() {
    return new AddManualBooking({
      sttManual: this.payload.sttManual,
      referenceExternal: this.payload.referenceExternal,
      productName: this.payload.productName,
      originCity: this.payload.originCity,
      originDistrict: this.payload.originDistrict,
      destinationCity: this.payload.destinationCity,
      destinationDistrict: this.payload.destinationDistrict,
      phoneSender: this.payload.phoneSender,
      phoneReceiver: this.payload.phoneReceiver,
      sender: this.payload.sender,
      receiver: this.payload.receiver,
      addressSender: this.payload.addressSender,
      addressReceiver: this.payload.addressReceiver,
      addressReceiverType: this.payload.addressReceiverType,
      sttGrossWeight: this.payload.sttGrossWeight,
      sttVolumeWeight: this.payload.sttVolumeWeight,
      sttChargeableWeight: this.payload.sttChargeableWeight,
      commodityCode: this.payload.commodityCode,
      insurance: this.payload.insurance,
      statusGoods: this.payload.statusGoods,
      estimationPrice: this.payload.estimationPrice,
      taxNumber: this.payload.taxNumber,
      isWoodpack: this.payload.isWoodpack,
      isCOD: this.payload.isCOD,
      isDFOD: this.payload.isDFOD,
      sttAttachFiles: this.payload.sttAttachFiles,
      sttCommodityDetail: this.payload.sttCommodityDetail,
      isSaveSender: this.payload.isSaveSender,
      isSaveReceiver: this.payload.isSaveReceiver,
      sttPieces: this.payload.sttPieces,
      isDO: this.payload.isDO,
      isMixpack: this.payload.isMixpack,
      sttPiecePerPack: this.payload.sttPiecePerPack,
      sttNextCommodity: this.payload.sttNextCommodity,
      sttCodAmount: this.payload.codAmount,
      postalCodeDestination: this.payload.postalCodeDestination,
      isPAD: this.payload.isPAD,
      sttWeightAttachFiles: this.payload.sttWeightAttachFiles,
      sttRecipientEmail: this.payload.sttRecipientEmail,
      sttKtpImage: this.payload.sttKtpImage,
      sttTaxImage: this.payload.sttTaxImage,
      sttIdentityNumber: this.payload.sttIdentityNumber,
      sttInterTaxNumber: this.payload.sttInterTaxNumber
    });
  }

  get isFromArchive() {
    return (
      this.$router.currentRoute.value.name === "archive-detail-stt" ||
      this.$router.currentRoute.value.name === "archive-tracking-stt"
    );
  }
  async generatePrintData() {
    return BookingController.generatePrinData({
      stt: this.STTID,
      accountType:
        AccountController.accountData.account_type === "partner"
          ? AccountController.accountData.account_type_detail.type
          : AccountController.accountData.account_type
    });
  }

  async generatePrintDataExternal(sttId: any) {
    return BookingController.generatePrinData({
      stt: sttId,
      accountType:
        AccountController.accountData.account_type === "partner"
          ? AccountController.accountData.account_type_detail.type
          : AccountController.accountData.account_type
    });
  }

  printData(): PrintDataEntitas {
    return BookingController.generatePrintData;
  }

  async bookingManual() {
    const book = await this.bookingStt();
    if (!book) {
      return;
    }
  }

  async bookPrintThermal() {
    const book = await this.bookingStt();
    if (!book) {
      return;
    }
    const print = await this.generatePrintData();

    if (book && print) {
      const accountType = AccountController.accountData.account_type;
      const payload = payloadOfThermalData(
        this.printData(),
        AccountController.accountData.account_type
      ).map((item: ThermalReceiptData) => ({
        ...item,
        isHubToHub: this.printData().isHubToHub,
        sttBookedForName: this.printData().sttBookedForName,
        otherShipperTicketCode: this.printData().otherShipperTicketCode,
        sttIsCodRetail:
          this.bookingTypePayload(this.printData()) === "manual" &&
          (accountType === "partner" || accountType === "internal") &&
          item.sttIsCod
      }));
      downloadFilePrn(payload, this.$i18n.locale);
      if (
        this.printData().sttProductType.toLowerCase() === "interpack" &&
        this.bookingType
      ) {
        this.printInterpack(this.printData());
      }
    }
  }

  async bookPrintDoc(type: number, autoPrint = false) {
    const book = await this.bookingStt();
    if (!book) {
      return;
    }
    const print = await this.generatePrintData();
    if (book && print) {
      await this.loadToPdf(
        type,
        this.$i18n.locale,
        false,
        this.bookingType,
        "",
        autoPrint
      );
    }
  }

  async printThermal(sttId: any, language = "id") {
    const print = await this.generatePrintDataExternal(sttId);

    if (print) {
      const accountType = AccountController.accountData.account_type;
      const payload = payloadOfThermalData(
        this.printData(),
        AccountController.accountData.account_type
      ).map((item: ThermalReceiptData) => ({
        ...item,
        isHubToHub: this.printData().isHubToHub,
        sttBookedForName: this.printData().sttBookedForName,
        otherShipperTicketCode: this.printData().otherShipperTicketCode,
        sttIsCodRetail:
          this.bookingTypePayload(this.printData()) === "manual" &&
          (accountType === "partner" || accountType === "internal") &&
          item.sttIsCod
      }));
      downloadFilePrn(payload, language);
    }
  }

  async printBasic(
    sttId: number,
    type: number,
    language = "id",
    withInvoice = false,
    autoPrint = false
  ) {
    MainAppController.showLoading();
    const print = await this.generatePrintDataExternal(sttId);

    if (print) {
      const printData = BookingController.generatePrintData;

      const bookingType = this.bookingTypePayload(printData);
      await this.loadToPdf(
        type,
        language,
        withInvoice,
        bookingType,
        printData.sttShipmentId,
        autoPrint
      );
    }
    MainAppController.closeLoading();
  }

  bookingTypePayload(printData: PrintDataEntitas) {
    let type = "client";
    if (printData.sttReverseJourneyShipmentId.match(/^ARA|^ARB|^AP|^AS/)) {
      type = "manual";
    }
    if (
      (printData.sttShipmentId && printData.sttClientId === 0) ||
      (printData.sttShipmentId && printData.sttClientId !== 0)
    ) {
      type = "shipment";
    }

    if (!printData.sttShipmentId && printData.sttClientId === 0) {
      type = "manual";
    }
    return type;
  }

  async loadToPdf(
    type: number,
    language = "id",
    withInvoice = false,
    bookingType = "",
    shipmentId = "",
    autoPrint = false,
    isBookInstan = false
  ) {
    await this.generateReceipt(
      this.printData(),
      bookingType,
      shipmentId,
      type,
      true,
      language,
      autoPrint,
      isBookInstan
    );
    if (
      this.printData().sttProductType.toLowerCase() === "interpack" &&
      (this.bookingType || withInvoice)
    ) {
      this.printInterpack(this.printData());
    }
  }
  async generateReceipt(
    printData: PrintDataEntitas,
    bookingType = "",
    shipmentId = "",
    type = 0,
    newTab = true,
    language = "id",
    autoPrint = false,
    isBookInstan = false
  ) {
    /* barcode */
    const barcode = document.createElement("img");
    JsBarcode(barcode, printData.sttNo, {
      textAlign: "left",
      displayValue: false,
      fontSize: 16
    });

    /* vendor ref code */
    let vendorRefCode: any = "";
    if (printData.sttVendorRefferenceNo) {
      vendorRefCode = document.createElement("img");
      JsBarcode(vendorRefCode, printData.sttVendorRefferenceNo, {
        textAlign: "left",
        displayValue: false,
        fontSize: 16
      });
    }

    if (type === 0) {
      await generateThermalPDFV2(
        bookingType,
        AccountController.accountData.account_type,
        printData,
        barcode.src,
        vendorRefCode?.src,
        printData.sttElexysNo || printData.sttNo,
        printData.sttVendorRefferenceNo,
        logoBlack,
        shipmentId,
        newTab,
        language,
        autoPrint,
        isBookInstan
      );
    } else {
      await generatePDFV2(
        bookingType,
        AccountController.accountData.account_type,
        printData,
        barcode.src,
        vendorRefCode?.src,
        printData.sttElexysNo || printData.sttNo,
        printData.sttVendorRefferenceNo,
        logoBlack,
        shipmentId,
        newTab,
        language
      );
    }
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get formType() {
    return this.$route.meta.formType;
  }
  balanceUnderLimit = false;
  get isBalanceUnderLimitForPos() {
    if (this.formType === "manual") {
      return (
        BalanceController.balanceData.walletBalance <
          this.dataProfile.limit_rule_min_balance &&
        this.dataProfile.account_type === "partner"
      );
    }
    return false;
  }

  async directPrintInterpack(sttId: number) {
    MainAppController.showLoading();
    const print = await this.generatePrintDataExternal(sttId);
    if (print) {
      const printData = BookingController.generatePrintData;
      await this.printInterpack(printData);
    }
    MainAppController.closeLoading();
  }

  async printInterpack(headerData: PrintDataEntitas) {
    const logo = await import("@/app/ui/assets/images/logo-lion-parcel.png");
    const locales = AccountController.accountData.account_type_detail.countryCode?.toLowerCase();
    generateCommercialInvoice(
      headerData,
      logo.default,
      locales === "my" ? "my" : undefined
    );
  }
}

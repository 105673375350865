
import {
  formatInputMoney,
  formatInputMoneyWithDecimal,
  parsingErrorResponse,
replaceComma,
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { InsuranceTypeDeliveryData } from "@/domain/entities/Booking";
import debounce from "lodash/debounce";
import { Options, prop, Vue } from "vue-class-component";
import InfoInsurance from "@/app/ui/views/shipment/booking/component/mini-banner/info-insurance-v2.vue";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { CodConfigurationController } from "@/app/ui/controllers/CodConfigurationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import Checkbox from "primevue/checkbox";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import SectionGoodsCategory from "../../create-booking-v2/components/section-goods-category.vue";
import { Commodity } from "@/domain/entities/Commodity";
import { isCustomerApps } from "@/app/ui/views/track-delivery/delivery-tab/modules";
import { InsuranceController } from "@/app/ui/controllers/InsuranceController";

class Props {
  formType = prop<string>({
    default: "",
    type: String,
  });
  errorEmbargo = prop<any>({
    default: {
      originCity: false,
      destinationCity: false,
      productType: false,
      comodity: false,
      weightStatus: false,
      lengthStatus: false,
    },
  });
  modelValue = prop<any>({
    required: true,
  });
  errorEstimationPrice = prop<any>({
    required: true,
  });
  errMessagePriceOutside = prop<string>({
    default: "",
  });
  estimationPrice = prop<any>({
    default: "0"
  })
  isCodAmount = prop<boolean>({
    default: false,
    type: Boolean,
  });
  deliveryFeeType = prop<string>({
    default: "",
    type: String,
  });
  isDisabledCommodity = prop<boolean>({
    default: false,
    type: Boolean
  });
  showGoodsCategory = prop<boolean> ({
    default: false,
    type: Boolean
  })
  goodsNames = prop<any[]>({
   default : []
  });
  isShowBanner = prop<boolean>({
    type: Boolean,
    default: true
  });
}
@Options({
  emits: ["update:modelValue", "setFieldIndexEstPrice", "checkFocus"],
  components: {
    InfoInsurance,
    Checkbox,
    SectionGoodsCategory
  },
  watch: {},
})
export default class SectionPackageDetail extends Vue.with(Props) {
  codStatusDisabled = false;
  dfodStatusDisabled = false;
  nonCodDisabled = false;
  isInsuranceBasic = false;
  oldCommodity = {
    id: 0,
    name: "",
    code: "",
    status: "",
    isDangerousGoods: false,
    isQuarantine: false,
  };

  get errorEstimationPriceModel() {
    return this.errorEstimationPrice;
  }
  set errorEstimationPriceModel(val: any) {
    this.$emit("update:errorEstimationPrice", val);
  }

  checkFocus() {
    this.$emit("checkFocus");
  }

  async mounted() {
    this.getOldCommodityDetail()
    this.codStatusDisabled = this.deliveryFeeType !== "cod" || !this.accountIsNonForeign;
    this.dfodStatusDisabled = this.deliveryFeeType !== "dfod" || !this.accountIsNonForeign;
    this.nonCodDisabled = this.deliveryFeeType !== "non-cod";
    this.getInsurancesTypeDelivery();
    this.onChangeInsurance();
    this.onSetInsuranceCondition();
    this.getCommodityList();
    await this.getInsurance();
    await this.fetchListConfigurationCOD();
  }

  get clientManagement() {
    return ClientManagementController.detailClientManagement;
  }

  setStatus(isCod = false, isDfod = false, nonCod = "") {
    this.codStatusDisabled = !isCod;
    this.dfodStatusDisabled = !isDfod;
    this.deliveryFeeType = nonCod;
  }

  get deliveryFeeTypes() {
    return [
      {
        name: "Non COD",
        value: "non-cod",
        disabled: this.nonCodDisabled,
        logo: "",
      },
      {
        name: "Cash On Delivery",
        value: "cod",
        disabled: this.codStatusDisabled,
        logo: "cod-badge",
      },
      {
        name: "Delivery Fee on Delivery",
        value: "dfod",
        disabled: this.dfodStatusDisabled,
        logo: "dfod-badge",
      },
    ];
  }

  requestData: ApiRequestList = new ApiRequestList();
  listDataConfigurationCOD: ResponsePayloadV2 = new ResponsePayloadV2();
  isEligibleCOD = false;
  isEligibleDFOD = false;
  isInsurance = false;
  listCodActive = [] as any;
  listDfodActive = [] as any;

  async handleDeliveryFeeType() {
    if (this.deliveryFeeType === "cod" || this.deliveryFeeType === "dfod") {
      await this.fetchListConfigurationCOD();
    } else {
      this.listCodActive = [];
      this.listDfodActive = [];
    }
  }

  get disabledBasic() {
    return (
      (this.listCodActive.ccoIsInsurance && this.deliveryFeeType === "cod") ||
      (this.listDfodActive.ccoIsInsurance && this.deliveryFeeType === "dfod")
    );
  }

  async fetchListConfigurationCOD() {
    //call this if formtype manual dan getAccount.accountType partner
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    try {
      this.listDataConfigurationCOD = await CodConfigurationController.getList(
        new ApiRequestList({
          page: this.listDataConfigurationCOD.pagination.page,
          limit: this.listDataConfigurationCOD.pagination.limit,
          status: this.requestData.statusData.value,
        })
      );
      // cod
      this.listCodActive = this.listDataConfigurationCOD.data.filter(
        (item: any) => item.ccoName === "COD Retail"
      )[0];

      // dfod
      this.listDfodActive = this.listDataConfigurationCOD.data.filter(
        (item: any) => item.ccoName === "DFOD Retail"
      )[0];

      if (this.disabledBasic) {
        this.isInsuranceBasic = !this.disabledBasic;
        this.disabledInsuranceBasic = this.disabledBasic;
        this.modelValue.insurance = "free";
      }
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Gagal Mendapat Konfigurasi COD !", () =>
          this.fetchListConfigurationCOD()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  onSetFieldIndexEstPrice() {
    // setFieldIndex(14);
    this.$emit("setFieldIndexEstPrice");
  }

  onChangeInsurance() {
    this.isInsuranceBasic = this.modelValue.sttInsuranceName?.toLowerCase()?.includes('basic');
    this.disabledInsuranceBasic = this.isInsuranceBasic;
    this.modelValue.insurance = this.isInsuranceBasic ? "basic" : "free";
    this.$emit("update:modelValue", {
      ...this.modelValue,
      insurance: this.isInsuranceBasic ? "basic" : "free",
    });
  }

  get infoMessageDetailGoods() {
    return [
      `Informasi Penting COD! ${
        this.formType === "client" ? "Client" : "POS"
      } tidak perlu menagih biaya pengiriman ke pengirim.`,
      `Informasi Penting DFOD! ${
        this.formType === "client" ? "Client" : "POS"
      } tidak perlu menagih biaya pengiriman ke pengirim.`,
    ];
  }

  async onChangeDeliveryFeeType(value: string) {
    BookingController.setCODAmount(0);
    this.deliveryFeeType = value;
    await this.handleDeliveryFeeType();
    this.fullFilledCOD(this.modelValue.sttGoodsEstimatePrice);
  }

  errorCommodity = false;

  changeCommodities(data: Commodity) {
    if (!data) {
      this.modelValue.sttCommodityName = this.oldCommodity
    }
    const activeElement = document.activeElement as any
    if (activeElement?.blur) {
      activeElement.blur()
    }
    this.errorCommodity = !this.modelValue.sttCommodityName;
    this.$emit("onChangeCommodities");
  }

  get manualBooking() {
    return BookingController.manualBooking;
  }

  isTabActive(value: string) {
    return value === this.deliveryFeeType;
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  get prefixCurrency() {
    return this.accountIsNonForeign ? "IDR" : "RM";
  }

  formatEstimationPrice(value: string) {
    return this.accountIsNonForeign
      ? this.formatRupiah(value)
      : this.formatRinggit(value);
  }

  formatRupiah(value: string) {
    return formatInputMoney(value.replace(/[\D]+/g, ""));
  }

  formatRinggit(value: any) {
    return formatInputMoneyWithDecimal(value);
  }

  get placeholderItemPrice() {
    let placeholder = this.$t("bookingShipment.placeholder.itemPrice");

    if (this.bookingClientForm && this.modelValue.insurance === "free") {
      placeholder = "e.g 10.000";
    }

    return placeholder;
  }

  get bookingClientForm() {
    return (
      this.bookingPosForClient ||
      this.bookingClientForClient ||
      this.bookingInternalForClient
    );
  }

  get isFormBookClient() {
    return this.formType === "client";
  }

  get isFormBookManual() {
    return this.formType === "manual";
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        this.formType === "client"
          ? false
          : AccountController.accountData.account_type_detail
              .partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail,
    };
  }

  get bookingPosForClient() {
    return this.isFormBookClient && this.getAccount.accountType === "partner";
  }

  get bookingClientForClient() {
    return this.isFormBookManual && this.getAccount.accountType === "client";
  }

  get bookingInternalForClient() {
    return this.isFormBookClient && this.getAccount.accountType === "internal";
  }

  insuranceCondition = "";

  onSetInsuranceCondition() {
    if (this.deliveryFeeType === "cod" && this.estimationPrice) {
      this.insuranceCondition = "cod";
    } else if (this.deliveryFeeType === "dfod" && this.estimationPrice) {
      this.insuranceCondition = "dfod";
    } else if (this.deliveryFeeType === "non-cod" && this.estimationPrice) {
      this.insuranceCondition = "non-cod";
    }
  }

  automaticSetCheckbox(
    price: any,
    isResetToBasic = false,
    disabledIfInsuranceYes = false
  ) {
    if (this.modelValue.productName.includes("OTOPACK")) {
      this.isInsuranceBasic = true;
      this.disabledInsuranceBasic = true;
      this.modelValue.insurance = "basic";
    } else {
      if (price >= 5000000 && !this.disabledBasic) {
        this.isInsuranceBasic = true;
        this.disabledInsuranceBasic = true;
        this.modelValue.insurance = "basic";
      } else {
        this.isInsuranceBasic = isResetToBasic;
        this.disabledInsuranceBasic = disabledIfInsuranceYes;
        this.modelValue.insurance = isResetToBasic ? "basic" : "free";
      }
    }
  }

  validationCOD(price: any) {
    if (!this.listCodActive.ccoIsInsurance) {
      const minMaxPrice =
        price < this.listCodActive.ccoMinPrice ||
        price > this.listCodActive.ccoMaxPrice;
      this.errorEstimationPriceModel = price && minMaxPrice;
      this.errMsgPrice =
        price > this.listCodActive.ccoMaxPrice
          ? `Harga barang COD maksimum ${formatPriceRP(
              this.listCodActive.ccoMaxPrice
            )}, cek & ubah lagi.`
          : `Harga barang COD minimum ${formatPriceRP(
              this.listCodActive.ccoMinPrice
            )}, cek & ubah lagi.`;
    } else {
      this.isInsuranceBasic = true;
      this.modelValue.insurance = "basic";
      this.disabledInsuranceBasic = true;
      // auto centang
    }
    this.errorEstimationPriceModel =
      price === 0 ||
      price < 10000 ||
      (this.manualBooking.productName.includes("OTOPACK") &&
        price > 0 &&
        price < 5000000);
    if (price === 0 || price < 10000) {
      this.errMsgPrice = "Harga barang minimum Rp10.000.";
    }
    if (
      this.manualBooking.productName.includes("OTOPACK") &&
      price > 0 &&
      price < 5000000
    ) {
      this.errMsgPrice = "Harga barang harus di atas atau sama dengan  5 juta";
    }
    this.automaticSetCheckbox(price, !this.disabledBasic, this.disabledBasic);
  }

  validationDFOD(price: any) {
    if (!this.listDfodActive.ccoIsInsurance) {
      const minMaxPrice =
        price < this.listDfodActive.ccoMinPrice ||
        price > this.listDfodActive.ccoMaxPrice;
      this.errorEstimationPriceModel = price === 0 || minMaxPrice;
      this.errMsgPrice =
        price > this.listDfodActive.ccoMaxPrice
          ? `Harga barang DFOD maksimum ${formatPriceRP(
              this.listDfodActive.ccoMaxPrice
            )}, cek & ubah lagi.`
          : `Harga barang DFOD minimum ${formatPriceRP(
              this.listDfodActive.ccoMinPrice
            )}, cek & ubah lagi.`;
    } else {
      this.isInsuranceBasic = true;
      this.modelValue.insurance = "basic";
      this.disabledInsuranceBasic = true;
      // auto centang
    }
    this.errorEstimationPriceModel =
      price === 0 ||
      price < 10000 ||
      (this.manualBooking.productName.includes("OTOPACK") &&
        price > 0 &&
        price < 5000000);
    if (price === 0 || price < 10000) {
      this.errMsgPrice = "Harga barang minimum Rp10.000.";
    }
    if (
      this.manualBooking.productName.includes("OTOPACK") &&
      price > 0 &&
      price < 5000000
    ) {
      this.errMsgPrice = "Harga barang harus di atas atau sama dengan  5 juta";
    }
    this.automaticSetCheckbox(price, !this.disabledBasic, this.disabledBasic);
  }

  isEstimationTyped = false;
  errMsgPrice = "";
  fullFilledCOD(price: any) {
    if (this.isCodAmount && this.deliveryFeeType == "cod") {
      BookingController.manualBooking.codAmount = price;
    }
    price = +price.replace(/\./g, "");
    if (this.deliveryFeeType === "cod") {
      this.validationCOD(price);
      BookingController.setCOD(true);
      BookingController.setDfod(false);
      return;
    } else if (this.deliveryFeeType === "dfod") {
      this.validationDFOD(price);
      BookingController.setCOD(true);
      BookingController.setDfod(true);
      return;
    }
    BookingController.setCOD(false);
    BookingController.setDfod(false);
    this.errorEstimationPriceModel =
      price === 0 ||
      price < 10000 ||
      (this.manualBooking.productName.includes("OTOPACK") &&
        price > 0 &&
        price < 5000000);
    if (price === 0 || price < 10000) {
      this.errMsgPrice = "Harga barang minimum Rp10.000.";
    }
    if (
      this.manualBooking.productName.includes("OTOPACK") &&
      price > 0 &&
      price < 5000000
    ) {
      this.errMsgPrice = "Harga barang harus di atas atau sama dengan  5 juta";
    }
    this.isInsuranceBasic = false;
    this.disabledInsuranceBasic = false;
    this.modelValue.insurance = "free";
    this.automaticSetCheckbox(price, !this.disabledBasic, this.disabledBasic);
  }

  dataListCommodities: Commodity[] = [];

  mapCommodity = (item: Commodity) => {
    return {
        id: item.commodity_id,
        name: this.accountIsNonForeign
          ? item.commodity_name
          : item.commodity_name_en,
        code: item.commodity_code,
        status: item.commodity_status,
        isDangerousGoods: item.isDangerousGoods,
        isQuarantine: item.isQuarantine
      };
  }

  get commodities() {
    return this.dataListCommodities.map(item => {
      return this.mapCommodity(item)
    });
  }

  disabledInsuranceBasic = false;

  getOldCommodityDetail = async () => {
    try {
      const detail = (await CommodityController.getCommodityDetail({id: this.modelValue.sttCommodityId})) as Commodity
      const mappedCommodity = this.mapCommodity(detail)
      this.modelValue.sttCommodityName = mappedCommodity
      this.oldCommodity = mappedCommodity
    } catch(e) {
      this.oldCommodity = this.modelValue.sttCommodityName
    }
  }

  fetchCommodityList = debounce(async (search: string) => {
    const commodities = await CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search,
        page: 1,
        limit: 20,
        commodityStatus: "active"
      })
    );
    this.dataListCommodities = commodities.data;
  }, 250);

  async fetchCommodityListInfiniteScroll(search = "") {
    const newSearch = search === (this.modelValue.sttCommodityName.name || this.modelValue.sttCommodityName) ? "" : search
    CommodityController.commodities.pagination.page++;
    const commodities = await CommodityController.getCommodityList(
      new RequestListCommodity({
        search: newSearch,
        page: CommodityController.commodities.pagination.page,
        limit: 20,
        commodityStatus: "active"
      })
    );
    this.dataListCommodities.push(...commodities.data);
  }

  get isLoadingCommodity() {
    return CommodityController.isLoading;
  }

  onFocusCommodityList() {
    // this.setFieldIndex(11);
    if (!this.modelValue.sttCommodityName) {
      this.getCommodityList();
    }
  }

  async getCommodityList() {
    const commodities = await CommodityController.getCommodityList(
      new RequestListCommodity({
        page: 1,
        limit: 20,
        commodityStatus: "active"
      })
    );
    this.dataListCommodities = commodities.data;
  }

  get insuranceDetail() {
    return BookingController.insuranceTypeDelivery.data.find(
      (item: InsuranceTypeDeliveryData) => item.value === "basic"
    );
  }

  get insuranceProfit() {
    const premi =
      (Number(this.insuranceDetail?.cost) / 100) *
      Number(
        this.modelValue.sttGoodsEstimatePrice
          .replace(/[.]/g, "")
          .replaceAll(",", ".")
      );
    const premiRound = this.accountIsNonForeign
      ? premi
      : (Math.ceil(premi * 10) / 10).toLocaleString(undefined, {
          maximumFractionDigits: 1,
        });

    const profit =
      (Number(this.insuranceDetail?.commissionPos) / 100) * Number(premiRound);
    return profit;
  }

  // insurance types
  listInsurance: any = [
    {
      name: "",
      value: "",
    },
  ];

  async getInsurancesTypeDelivery() {
    await BookingController.getInsuranceTypesDelivery({
      insuranceConfig: 2,
    });

    this.listInsurance = BookingController.insuranceTypeDelivery.data.map(
      (e: InsuranceTypeDeliveryData) => {
        return {
          name: e.name,
          value: e.value,
          status: e.status,
          cost: e.cost,
          commissionPos: e.commissionPos,
          maxProtection: e.maxProtection,
        };
      }
    );
  }

  get formData(): any {
    return { ...this.modelValue, estimationPrice: this.modelValue.sttGoodsEstimatePrice, isInsurance: this.isInsuranceBasic, listCodActive: this.listCodActive, listDfodActive: this.listDfodActive };
  }

  get accountData() {
    return AccountController.accountData;
  }
  get bookingRetail() {
    return this.accountData.account_type && this.formType === "manual";
  }
  get isCA(): boolean {
    return isCustomerApps(this.modelValue?.sttShipmentId);
  }
  validationGetInsurance(value: number): boolean {
    return (!!value && (this.bookingRetail || this.isCA) && this.accountData.isPosAccount);
  }
  async getInsurance() {
    const goodsPrice = +replaceComma(this.formData.estimationPrice);
    if (this.validationGetInsurance(goodsPrice)) {
      try {
        const params = new ApiRequestList({
          page: undefined,
          limit: undefined,
          typeInsurance: this.isInsuranceBasic ? "basic" : "free",
          goodsPrice,
          country: this.accountData.countryCode
        });

        if (this.bookingRetail) {
          params.accountType = "pos";
          params.accountRefId = this.accountData.account_type_detail?.id;
        }

        if (this.isCA) {
          params.accountType = "client";
          params.accountRefId = 2311;
        }

        const res = await InsuranceController.getInsurance(params);
        InsuranceController.setInsurance(res);
      } catch (error) {
        console.log(error);
      }
    }
  }
}


import { Options, prop, Vue } from "vue-class-component";
import {
  parsingErrorResponse,
  removeNewLineWhitespace,
} from "@/app/infrastructures/misc/Utils";
import { BookingController } from "@/app/ui/controllers/BookingController";
import {
  DeleteHydraFileRequest,
  UploadHydraFileRequest,
} from "@/data/payload/api/BookingRequest";
import { UploadMultiple } from "@/domain/entities/MainApp";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { InternationalDocumentController } from "@/app/ui/controllers/InternationalDocumentController";

class Props {
  modelValue = prop<any>({
    required: true,
  });
  error = prop<any>({
    required: true,
  });
  errorCommodity = prop<boolean>({
    default: false,
    type: Boolean,
  });
  errorEmail = prop<boolean>({
    default: false,
    type: Boolean,
  });
  errorSttIdentityNumber = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorSttInterTaxNumber = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorPhoto = prop<boolean>({
    default: false,
    type: Boolean,
  });
  commodity = prop<string>({
    default: "",
    type: String,
  });
  photos = prop<string[]>({
    default: [],
    type: Array,
  });
  currentIndexError = prop<number>({
    default: 0,
    type: Number,
  });
  indexErrorCommodity = prop<number>({
    default: 0,
    type: Number,
  });
  indexErrorEmail = prop<number>({
    default: 0,
    type: Number,
  });
  indexErrorSttIdentityNumber = prop<number>({
    default: 0,
    type: Number
  });
  indexErrorSttInterTaxNumber = prop<number>({
    default: 0,
    type: Number
  });
  indexErrorPhoto = prop<number>({
    default: 0,
    type: Number,
  });
  limitError = prop<number>({
    default: 0,
    type: Number,
  });
  originCity = prop<number>({
    default: 0,
    type: Number,
  });
  destinationCity = prop<number>({
    default: 0,
    type: Number,
  });
}
@Options({
  emits: [
    "update:modelValue",
    "update:error",
    "updateCommodity",
    "updateFiles",
    "setFieldIndex",
  ],
  components: {
    // call your component here
  },
  watch: {
    "isErrorFile": function() {
      this.setErrorFiles();
    },
    "internationalDocumentDetail.email": function(value) {
      if (!value && !this.modelValue.email) {
        this.error.email = false;
      }
    },
    "isDataReset": function() {
      this.resetStoreImage();
    }
  }
})
export default class SectionAdditionalInfoInterpack extends Vue.with(Props) {
  mounted() {
    this.setErrorFiles();
    this.getInternationalDocumentDetail();
  }
  replaceNewLine(value: string) {
    return removeNewLineWhitespace(value, " ");
  }
  setFieldIndex(index: number) {
    this.$emit("setFieldIndex", index);
  }

  validateFormatEmail(value = "") {
    return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  validateEmail(value: string) {
    if (this.internationalDocumentDetail.email) {
      this.error.email = this.validateFormatEmail(value);
    } else {
      this.error.email = false;
    }
    if (value) {
      this.error.email = this.validateFormatEmail(value);
    }
    this.$emit("update:modelValue", {
      ...this.modelValue,
      email: value,
    });
    this.$emit("update:error", {
      ...this.error,
      email: this.error.email,
    });
  }
  get errorEmailCaption() {
    if (this.internationalDocumentDetail.email && !this.modelValue.email) {
      return this.$t("bookingShipment.error.additionalInfoEmailRequired");
    }

    if (this.modelValue.email && this.error.email) {
      return this.$t("bookingShipment.error.additionalInfoEmailFormat");
    }

    return "";
  }

  validateCommodity(value: string) {
    if (this.internationalDocumentDetail.commodity) {
      this.error.commodity = !value;
    }
    this.$emit("update:modelValue", {
      ...this.modelValue,
      commodity: value,
    });
    this.$emit("update:error", {
      ...this.error,
      commodity: this.error.commodity,
    });
  }

  validateIdentityNumber(value: string) {
    this.$el.querySelector(
      "#input_nomor_id_penerima_interpack_create_booking"
    ).value = value.replace(/[^a-zA-Z0-9]/g, "");

    if (this.internationalDocumentDetail.idcIsIdentityNumber) {
      this.error.sttIdentityNumber = !value;
    }

    this.$emit("update:error", {
      ...this.error,
      sttIdentityNumber: this.error.sttIdentityNumber
    });
    this.$emit("update:modelValue", {
      ...this.modelValue,
      sttIdentityNumber: value.replace(/[^a-zA-Z0-9]/g, "")
    });
  }

  validateInterTaxNumber(value: string) {
    this.$el.querySelector(
      "#input_nomor_tax_id_penerima_interpack_create_booking"
    ).value = value.replace(/[^a-zA-Z0-9]/g, "");
    this.$emit("update:modelValue", {
      ...this.modelValue,
      sttInterTaxNumber: value.replace(/[^a-zA-Z0-9]/g, "")
    });
    if (this.internationalDocumentDetail.idcIsTaxIdentificationNumber) {
      this.error.sttInterTaxNumber = !value;
    }
    this.$emit("update:error", {
      ...this.error,
      sttInterTaxNumber: this.error.sttInterTaxNumber
    });
  }

  attachmentImage: any = [];
  seedImages = new Array(4).fill("");
  storageImages: any = this.seedImages.map(
    (item: string, index: number) => this.photos[index]
  );

  isError = false;
  errorMessage = "";

  async upload(params: UploadMultiple) {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    this.setFieldIndex(this.indexErrorPhoto);
    this.isError = false;
    this.errorMessage = "";
    try {
      if (params.file) {
        const fileLink = await BookingController.uploadHydraFile(
          new UploadHydraFileRequest({
            attachFile: params.file,
            folderPath: "stt/",
          })
        );
        this.storageImages[params.index] = fileLink.data;
        this.updateFiles();
      }
    } catch (err) {
      this.isError = true;
      this.errorMessage = this.$t("bookingShipment.error.uploadPhoto", {
        value: params.index + 1,
      });
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, this.$t("bookingShipment.error.uploadPhoto"))
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  get proofPhotos() {
    return [
      this.$t("revamp.booking.packageBeforePacking"),
      this.$t("revamp.booking.packageAfterPacking"),
      `<br/>${this.$t("revamp.booking.recipientId")}`,
      "<br/>Tax ID",
    ];
  }

  async remove(index: number) {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    this.isError = false;
    this.errorMessage = "";
    try {
      const del = await BookingController.deleteHydraFile(
        new DeleteHydraFileRequest({
          attachFiles: [this.storageImages[index]],
        })
      );
      const statusDel = del.data[this.storageImages[index]];
      statusDel ? this.deleteFiles(index) : this.erroMessageDelete(index);
    } catch (err) {
      this.erroMessageDelete(index);
    } finally {
      MainAppController.closeLoading();
    }
  }

  async updateFiles() {
    this.$emit("update:modelValue", {
      ...this.modelValue,
      files: this.storageImages,
    });
    await this.$nextTick()
    this.setErrorFiles();
  }
  
  setErrorFiles() {
    this.$emit("update:error", {
      ...this.error,
      files: this.isErrorFile,
    });
  }

  get isErrorFile() {
    return (
      (this.internationalDocumentDetail.afterPacking && 
        (!this.modelValue.files[0] || this.modelValue.files[0] === "" || this.modelValue.files[0] === null)) ||
      (this.internationalDocumentDetail.afterPacking && 
        (!this.modelValue.files[1] || this.modelValue.files[1] === "" || this.modelValue.files[1] === null)) ||
      (this.internationalDocumentDetail.ktp && 
        (!this.modelValue.files[2] || this.modelValue.files[2] === "" || this.modelValue.files[2] === null)) ||
      (this.internationalDocumentDetail.npwp && 
        (!this.modelValue.files[3] || this.modelValue.files[3] === "" || this.modelValue.files[3] === null))
    );
  }

  get isDataReset() {
    return this.modelValue.files.length < 1 && this.attachmentImage.length > 0;
  }

  resetStoreImage() {
    this.storageImages = [];
    this.attachmentImage = [];
  }

  get mandatoryUploadFile() {
    return (
      this.internationalDocumentDetail.afterPacking ||
      this.internationalDocumentDetail.ktp || 
      this.internationalDocumentDetail.npwp
    )
  }

  deleteFiles(index: number) {
    this.storageImages[index] = "";
    this.updateFiles();
  }

  erroMessageDelete(index: number) {
    this.deleteFiles(index);
  }

  async getInternationalDocumentDetail() {
    await InternationalDocumentController.getInternationalDocumentDetail({
      origin: this.originCity,
      dest: this.destinationCity
    })
  }

  get internationalDocumentDetail() {
    return InternationalDocumentController.internationalDocumentDetail;
  }

  get errorCommodityMandatory() {
    return this.internationalDocumentDetail.commodity ? this.errorCommodity : false
  }

  get errorEmailMandatory() {
    return this.internationalDocumentDetail.email ? this.errorEmail : false
  }

  get errorSttIdentityNumberMandatory() {
    return this.internationalDocumentDetail.idcIsIdentityNumber
      ? this.errorSttIdentityNumber
      : false;
  }

  get errorSttInterTaxNumberMandatory() {
    return this.internationalDocumentDetail.idcIsTaxIdentificationNumber
      ? this.errorSttInterTaxNumber
      : false;
  }
}

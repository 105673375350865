
import { Options, Vue, prop } from "vue-class-component";
import ButtonBooking from "@/app/ui/components/button-list/index.vue";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import {
  payloadOfThermalData,
  downloadFilePrn
} from "@/app/infrastructures/misc/UtilsThermal";
import { GTMOnProcessSttEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/on-process-stt-events";
import { ThermalReceiptData } from "@/domain/entities/Printer";
import { EditBooking } from "@/data/payload/api/BookingRequest";
interface Diskon {
  isDiscount: boolean;
  totalTariff: number;
  totalAfterDiscount: number;
  totalBeforeDiscount: number;
}
class Props {
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  payload = prop<any>({
    default: {},
    type: Object
  });
  bookingType = prop<any>({
    default: "",
    type: String
  });
  onBooking = prop<any>({
    type: Function
  });
  isDiscountActive = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits: ["setDiscount"],
  components: {
    ButtonBooking
  }
})
export default class Book extends Vue.with(Props) {
  get isMigratingFromElexys() {
    return process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true";
  }

  get menus() {
    return [
      {
        title: this.$t('bookingShipment.bookButton.printLater'),
        icon: "check-circle-border"
      },
      {
        title: this.$t('bookingShipment.bookButton.printThermal'),
        icon: "printer"
      },
      {
        title: this.$t('bookingShipment.bookButton.printThermalPdf'),
        icon: "document-download-border"
      },
      {
        title: this.$t('bookingShipment.bookButton.printBasic'),
        icon: "document-download-border"
      }
    ];
  }

  async action(index: number) {
    if (String(this.$route.name).match(/on-process-stt/)) {
      GTMOnProcessSttEvent("on_process_stt_edit_submit", {
        statusStt: this.payload.sttLastStatus,
        sttNumber: this.payload.sttNo
      });
    }
    switch (index) {
      case 0:
        this.bookPrintLater();
        break;
      case 1:
        await this.bookPrintThermal();
        break;
      case 2:
        await this.bookPrintDoc("thermal-pdf");
        break;
      default:
        await this.bookPrintDoc("basic");
        break;
    }
  }

  async bookPrintLater() {
    const book: any = await BookingController.editBooking(this.payloadEditBook);
    if (book.isDiscount) {
      const params: Diskon = {
        isDiscount: book.isDiscount,
        totalTariff: book.totalDiscount + book.totalAfterDiscount,
        totalAfterDiscount: book.totalAfterDiscount,
        totalBeforeDiscount: book.totalBeforeDiscount
      };
      this.$emit("setDiscount", params);
    }
  }

  async bookingAndPrintSaveDoc() {
    const book: any = await BookingController.editBookingThenPrint(
      this.payloadEditBook
    );
    if (book.isDiscount) {
      const params: Diskon = {
        isDiscount: book.isDiscount,
        totalTariff: book.totalDiscount + book.totalAfterDiscount,
        totalAfterDiscount: book.totalAfterDiscount,
        totalBeforeDiscount: book.totalBeforeDiscount
      };
      this.$emit("setDiscount", params);
    }
    return book;
  }

  get payloadEditBook() {
    return new EditBooking({
      id: this.payload.id,
      isMixpack: this.payload.isMixpack,
      sttNoRefExternal: this.payload.sttNoRefExternal,
      sttTaxNumber: this.payload.sttTaxNumber,
      sttGoodsEstimatePrice: this.payload.sttGoodsEstimatePrice,
      sttGoodsStatus: this.payload.sttGoodsStatus,
      sttOriginCityId: this.payload.sttOriginCityId,
      sttOriginDistrictId: this.payload.sttOriginDistrictId,
      sttDestinationCityId: this.payload.sttDestinationCityId,
      sttDestinationDistrictId: this.payload.sttDestinationDistrictId,
      sttSenderName: this.payload.sttSenderName,
      sttSenderPhone: this.payload.sttSenderPhone,
      sttSenderAddress: this.payload.sttSenderAddress,
      sttIsSaveSender: this.payload.sttIsSaveSender,
      sttRecipientName: this.payload.sttRecipientName,
      sttRecipientAddress: this.payload.sttRecipientAddress,
      sttRecipientAddressType: this.payload.sttRecipientAddressType,
      sttRecipientPhone: this.payload.sttRecipientPhone,
      sttIsSaveRecipient: this.payload.sttIsSaveRecipient,
      sttInsuranceType: this.payload.sttInsuranceType,
      sttProductType: this.payload.sttProductType,
      sttCommodityCode: this.payload.sttCommodityCode,
      sttClientSttId: this.payload.sttClientSttId,
      sttVendorSttId: this.payload.sttVendorSttId,
      sttIsCod: this.payload.sttIsCod,
      sttIsDfod: this.payload.sttIsDfod,
      sttWeightAttachFiles: this.payload.sttWeightAttachFiles,
      sttAttachFiles: this.payload.sttAttachFiles,
      sttCommodityDetail: this.payload.sttCommodityDetail,
      codHandling: this.payload.codHandling,
      sttIsDo: this.payload.sttIsDo,
      sttIsWoodpacking: this.payload.sttIsWoodpacking,
      sttPieces: this.payload.sttPieces,
      sttPiecePerPack: this.payload.sttPiecePerPack,
      sttNextCommodity: this.payload.sttNextCommodity,
      sttCodAmount: this.payload.sttCodAmount,
      postalCodeDestination: this.payload.postalCodeDestination,
      sttIsPad: this.payload.sttIsPad,
      discountFavoritePercentage: this.payload.discountFavoritePercentage,
      sttRecipientEmail: this.payload.sttRecipientEmail,
      sttKtpImage: this.payload.sttKtpImage,
      sttTaxImage: this.payload.sttTaxImage,
      sttIdentityNumber: this.payload.sttIdentityNumber,
      sttInterTaxNumber: this.payload.sttInterTaxNumber
    })
  }

  get STTID() {
    return BookingController.sttId;
  }

  async generatePrintData() {
    return BookingController.generatePrinData({
      stt: this.STTID,
      accountType:
        AccountController.accountData.account_type === "partner"
          ? AccountController.accountData.account_type_detail.type
          : AccountController.accountData.account_type
    });
  }

  get printData() {
    return BookingController.generatePrintData;
  }

  async bookPrintThermal() {
    const book: any = await this.bookingAndPrintSaveDoc();
    if (!book) {
      return;
    }
    const print = await this.generatePrintData();
    if (book && print) {
      const accountType = AccountController.accountData.account_type;
      const payload = payloadOfThermalData(this.printData, accountType).map(
        (item: ThermalReceiptData) => ({
          ...item,
          isHubToHub: this.printData.isHubToHub,
          sttBookedForName: this.printData.sttBookedForName,
          otherShipperTicketCode: this.printData.otherShipperTicketCode,
          sttIsCodRetail:
            !this.printData.sttShipmentId &&
            !this.printData.sttClientId &&
            (accountType === "partner" || accountType === "internal") &&
            item.sttIsCod
        })
      );
      downloadFilePrn(payload, this.$i18n.locale);
      const printedLoad: any = await import(
        "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
      );
      if (this.printData.sttProductType.toLowerCase() === "interpack") {
        printedLoad.default.methods.directPrintInterpack(this.printData.sttId);
      }
    }
  }

  async bookPrintDoc(type: string, language = "id") {
    const book: any = await this.bookingAndPrintSaveDoc();
    if (!book) {
      return;
    }
    if (book) {
      const sttId = BookingController.sttId;
      const printedLoad: any = await import(
        "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
      );
      const printType: any = {
        "thermal-pdf": () =>
          printedLoad.default.methods.printBasic(
            sttId,
            0,
            language,
            false,
            true
          ),
        basic: () =>
          printedLoad.default.methods.printBasic(
            sttId,
            1,
            this.$i18n.locale,
            true
          )
      };

      printType[type]();
    }
  }
}

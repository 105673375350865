
import { Options, Vue, prop } from "vue-class-component";
import isDisableSttManual from "@/app/infrastructures/misc/common-library/IsDisableSttManual";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import router from "@/app/ui/router";
import { debounce } from "lodash";

class Props {
  modelValue = prop<any>({
    required: true
  });
  error = prop<any>({
    required: true
  });
  formType = prop<string>({
    default: "",
    type: String
  });
  isFirstInput = prop<boolean>({
    default: false,
    type: Boolean
  });
  isClientDO = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorMandatory = prop<any>({
    default: false,
    type: Boolean
  });
  isDO = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits:["setFieldIndex"],
})
export default class SectionSTTManualReference extends Vue.with(Props) {

  errorCaptionSttManual = ""
  errorReferenceNumber = ""
  isErrorRefNumber = false

  get isSelectClient() {
    if (this.formType !== "manual") {
      return this.modelValue.clientName == ""
    } else {
      return false
    }
  }

  get isDisabledSttManual() {
    return isDisableSttManual(this.formType) && this.isSelectClient;
  }

  validateSttManual = debounce(async (value: string, modelValue: any) => {
    if (value === "") {
      this.errorCaptionSttManual = "";
      this.error.sttManual = false;
      return;
    } else if (value.length < 3) {
      this.errorCaptionSttManual = "Format nomor STT manual minimum 3 angka.";
      this.error.sttManual = true;
    } else {
    const result = await BookingController.validateSttManual({
      clientId: this.getValidateSttManual(modelValue),
      sttManual: value,
      bookingType:
        router.currentRoute.value.meta.formType === "manual" &&
        this.getAccount.accountType === "partner"
          ? "pos"
          : "client",
      productType: modelValue.productName
    });
    this.error.sttManual = !result.isAllowBooking;
    this.errorCaptionSttManual = result.errorMessage;
    }
  }, 250);



  validateRefNumber(value: string) {
    if (value === "") {
      this.errorReferenceNumber = "";
      this.isErrorRefNumber = false;
      this.error.errorRefExternal = false;
    } else if (value.length < 3) {
      this.errorReferenceNumber = this.$t('bookingShipment.error.minimumRef');
      this.isErrorRefNumber = true;
      this.error.errorRefExternal = true;
    } else {
      this.errorReferenceNumber = "";
      this.isErrorRefNumber = false;
      this.error.errorRefExternal = false
    }
  }


  getValidateSttManual(modelValue: any) {
    if (router.currentRoute.value.meta.formType === "client") {
      return modelValue.clientName.id;
    } else if (this.getAccount.accountType === "client") {
      return this.getAccount.accountClientId;
    } else {
      return "";
    }
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        AccountController.accountData.account_type_detail.partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail,
    };
  }

  get getErrorMandatory() {
    return this.isErrorRefNumber || this.errorMandatory;
  }


  setErroMandatory() {
    this.$emit("setFieldIndex", 2);
  }

 

  get refNumberOpsionalOrAsterik() {
    return !this.isClientDO && !this.isDO;
  }

}

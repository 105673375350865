
import { formattedNPWP } from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { Options, prop, Vue } from "vue-class-component";
class Props {
  modelValue = prop<any>({
    required: true
  });
  error = prop<any>({
    required: true
  });
  formType = prop<string>({
    default: "",
    type: String
  });
}
@Options({
  emits: ["update:modelValue", "update:error"],
  components: {
    // call your component here
  }
})
export default class SectionFTZ extends Vue.with(Props) {
  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  get isFtzAccount() {
    return this.getAccount.accountType === "internal"
      ? this.modelValue.originCity?.isFtz === "yes"
      : AccountController.accountData.account_location?.city_free_trade_zone ===
          "yes";
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        AccountController.accountData.account_type_detail.partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail
    };
  }

  formatted(value: string) {
    const newValue = value.replace(/[^0-9.-]/g, "");
    return newValue;
  }
  validateTaxNumber(value: string) {
    this.error.taxNumber = !/[0-9-.]+/.test(value);
    if (value.length < 16) {
      this.error.taxNumber = true;
    }
    this.$emit("update:error", {
      ...this.error,
      taxNumber: this.error.taxNumber
    });
  }

  handleErrorTaxNumber() {
    if (!this.modelValue.taxNumber) {
      this.error.taxNumber = false;
      this.$emit("update:error", {
        ...this.error,
        taxNumber: false
      });
    }
  }

  errorStatusGoods = false;
  errorNextCommodity = false;
  errorPiecePerPack = false;

  get errorManadatoryShipmentStatus() {
    return this.isFtzAccount || this.formType === "client";
  }

  isStatusGoods = false;

  onOpenSelect() {
    this.isStatusGoods = true;
  }

  onCloseSelect() {
    this.isStatusGoods = false;
  }

  onSelectStatus(key: string, value: string) {
    BookingController.setStatusGoods(value);
    this.errorStatusGoods = !this.modelValue.statusGoods;
  }

  get listStatusGoodsForIndonesian() {
    return [
      { name: "Masukkan status pengiriman", value: "" },
      { name: "Ecommerce", value: "ecommerce" },
      { name: "Produk lokal / Batam", value: "batam" },
      { name: "Eks luar Batam / transit", value: "transit" },
      { name: "Salah kirim / return", value: "return" },
      { name: "Personal effect", value: "personal-effect" }
    ];
  }

  get listStatusGoodsForEnglish() {
    return [
      { name: "Select shipment status", value: "" },
      { name: "Retail", value: "retail" },
      { name: "Diplomatic Pouch", value: "diplomatic-pouch" },
      { name: "Personal Effect", value: "personal-effect" },
      { name: "Marketplace", value: "ecommerce" }
    ];
  }

  formatPiecePerPack(value: string) {
    return value.replace(/[\D]+/g, "").replace(/\D|^0+/g, "");
  }
}


import { Options, prop, Vue } from "vue-class-component";
import {
  parsingErrorResponse,
  removeNewLineWhitespace,
} from "@/app/infrastructures/misc/Utils";
import { BookingController } from "@/app/ui/controllers/BookingController";
import {
  DeleteHydraFileRequest,
  UploadHydraFileRequest,
} from "@/data/payload/api/BookingRequest";
import { UploadMultiple } from "@/domain/entities/MainApp";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { InternationalDocumentController } from "@/app/ui/controllers/InternationalDocumentController";
import { RequestInternationalDocumentDetail } from "@/domain/entities/InternationalDocument";

class Props {
  modelValue = prop<any>({
    required: true,
  });
  error = prop<any>({
    required: true,
  });
  errorCommodity = prop<boolean>({
    default: false,
    type: Boolean,
  });
  errorPhoto = prop<boolean>({
    default: false,
    type: Boolean,
  });
  commodity = prop<string>({
    default: "",
    type: String,
  });
  photos = prop<string[]>({
    default: [],
    type: Array,
  });
  currentIndexError = prop<number>({
    default: 0,
    type: Number,
  });
  indexErrorCommodity = prop<number>({
    default: 0,
    type: Number,
  });
  indexErrorPhoto = prop<number>({
    default: 0,
    type: Number,
  });
  limitError = prop<number>({
    default: 0,
    type: Number,
  });
}
@Options({
  emits: [
    "update:modelValue",
    "update:error",
    "updateCommodity",
    "updateFiles",
    "setFieldIndex",
  ],
  components: {
    // call your component here
  },
})
export default class SectionAdditionalInfoInterpack extends Vue.with(Props) {
  async mounted() {
    // this.$emit("update:error", {
    //   ...this.error,
    //   files: this.isErrorFile,
    // });
    await this.getInternationalDocumentDetail();

  }

  async getInternationalDocumentDetail() {
    try {
      await InternationalDocumentController.getInternationalDocumentDetail({
        origin: this.modelValue?.sttOriginCity, // id kota asal dari detail
        dest: this.modelValue?.sttDestinationCity // id kota tujuan dari detail
      });
    } finally {
      this.validateEmail(this.modelValue.sttRecipientEmail);
      this.validationSttIdentityNumber(this.modelValue.sttIdentityNumber);
      this.validationSttInterTaxNumber(this.modelValue.sttInterTaxNumber);
      this.updateFiles();
    }
  }

  get internationalDocumentDetail(): RequestInternationalDocumentDetail {
    return InternationalDocumentController.internationalDocumentDetail;
  }

  isDirtySttIdentityNumber = false;
  inputSttIdentityNumber(value: string) {
    this.isDirtySttIdentityNumber = true;
    this.validationSttIdentityNumber(value);
  }
  validationSttIdentityNumber(value: string) {
    this.error.sttIdentityNumber =
      (this.internationalDocumentDetail.idcIsIdentityNumber && !value) ||
      value?.length > 100;

    this.$emit("update:error", {
      ...this.error,
      sttIdentityNumber: this.error.sttIdentityNumber
    });
  }
  isDirtySttInterTaxNumber = false;
  inputSttInterTaxNumber(value: string) {
    this.isDirtySttInterTaxNumber = true;
    this.validationSttInterTaxNumber(value);
  }
  validationSttInterTaxNumber(value: string) {
    this.error.sttInterTaxNumber =
      (this.internationalDocumentDetail.idcIsTaxIdentificationNumber &&
        !value) ||
      value?.length > 100;

    this.$emit("update:error", {
      ...this.error,
      sttInterTaxNumber: this.error.sttInterTaxNumber
    });
  }

  get errorSttIdentityNumber() {
    const required =
      this.internationalDocumentDetail.idcIsIdentityNumber &&
      !this.modelValue.sttIdentityNumber &&
      this.isDirtySttIdentityNumber;

    if (required) {
      return this.$t("bookingShipment.error.sttIdentityNumberRequired");
    }

    if (this.modelValue.sttIdentityNumber?.length > 100) {
      return this.$t("bookingShipment.error.sttIdentityNumberMaxLength");
    }

    return "";
  }
  get errorSttInterTaxNumber() {
    const required =
      this.internationalDocumentDetail.idcIsTaxIdentificationNumber &&
      !this.modelValue.sttInterTaxNumber &&
      this.isDirtySttInterTaxNumber;

    if (required) {
      return this.$t("bookingShipment.error.sttInterTaxNumberRequired");
    }

    if (this.modelValue.sttInterTaxNumber?.length > 100) {
      return this.$t("bookingShipment.error.sttInterTaxNumberMaxLength");
    }

    return "";
  }

  replaceNewLine(value: string) {
    return removeNewLineWhitespace(value, " ");
  }
  setFieldIndex(index: number) {
    this.$emit("setFieldIndex", index);
  }

  get errorEmail() {
    const required =
      this.internationalDocumentDetail.email &&
      !this.modelValue.sttRecipientEmail &&
      this.isDirtyEmail;
    if (required) {
      return this.$t("bookingShipment.error.additionalInfoEmailRequired");
    }

    if (this.modelValue.sttRecipientEmail && this.error.email) {
      return this.$t("bookingShipment.error.additionalInfoEmailFormat");
    }

    return "";
  }
  isDirtyEmail = false;
  inputEmail(value: string) {
    this.isDirtyEmail = true;
    this.validateEmail(value);
  }
  validateEmail(value: string) {
    this.error.email =
      (this.internationalDocumentDetail.email || value) &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    this.$emit("update:error", {
      ...this.error,
      email: this.error.email
    });
  }

  validateCommodity(value: string) {
    this.error.commodity = !value;
    this.$emit("update:error", {
      ...this.error,
      commodity: this.error.commodity,
    });
  }

  attachmentImage: any = [];
  seedImages = new Array(4).fill("");
  storageImages: any = this.seedImages.map(
    (item: string, index: number) => this.photos[index]
  );

  disabledFields = [
    !!this.modelValue.sttCommodityDetail ||
      (this.internationalDocumentDetail.commodity &&
        !!this.modelValue.sttCommodityDetail),
    !!this.modelValue.sttRecipientEmail ||
      (this.internationalDocumentDetail.email &&
        !!this.modelValue.sttRecipientEmail),
    !!this.modelValue.sttIdentityNumber ||
      (this.internationalDocumentDetail.idcIsIdentityNumber &&
        !!this.modelValue.sttIdentityNumber),
    !!this.modelValue.sttInterTaxNumber ||
      (this.internationalDocumentDetail.idcIsTaxIdentificationNumber &&
        !!this.modelValue.sttInterTaxNumber),
    !!this.storageImages?.[0] ||
      (this.internationalDocumentDetail.afterPacking &&
        !!this.storageImages?.[0]),
    !!this.storageImages?.[1] ||
      (this.internationalDocumentDetail.afterPacking &&
        !!this.storageImages?.[1]),
    !!this.storageImages?.[2] ||
      (this.internationalDocumentDetail.ktp && !!this.storageImages?.[2]),
    !!this.storageImages?.[3] ||
      (this.internationalDocumentDetail.npwp && !!this.storageImages?.[3])
  ];

  isError = false;
  errorMessage = "";

  async upload(params: UploadMultiple, index: number) {
    if (this.disabledFields[index]) return;

    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    this.setFieldIndex(this.indexErrorPhoto);
    this.isError = false;
    this.errorMessage = "";
    try {
      if (params.file) {
        const fileLink = await BookingController.uploadHydraFile(
          new UploadHydraFileRequest({
            attachFile: params.file,
            folderPath: "stt/",
          })
        );
        this.storageImages[params.index] = fileLink.data;
        this.updateFiles();
      }
    } catch (err) {
      this.isError = true;
      this.errorMessage = this.$t("bookingShipment.error.uploadPhoto", {
        value: params.index + 1,
      });
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, this.$t("bookingShipment.error.uploadPhoto"))
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  get proofPhotos() {
    return [
      "Paket sebelum packing",
      "Paket sesudah packing",
      "<br/>KTP",
      "<br/>NPWP",
    ];
  }

  async remove(index: number, disabledIndex: number) {
    if (this.disabledFields[disabledIndex]) return;

    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    this.isError = false;
    this.errorMessage = "";
    try {
      const del = await BookingController.deleteHydraFile(
        new DeleteHydraFileRequest({
          attachFiles: [this.storageImages[index]],
        })
      );
      const statusDel = del.data[this.storageImages[index]];
      statusDel ? this.deleteFiles(index) : this.erroMessageDelete(index);
    } catch (err) {
      this.erroMessageDelete(index);
    } finally {
      MainAppController.closeLoading();
    }
  }

  updateFiles() {
    this.$emit("update:modelValue", {
      ...this.modelValue,
      sttAttachFiles: [this.storageImages[0], this.storageImages[1]],
      sttKtpImage: this.storageImages[2],
      sttTaxImage: this.storageImages[3]
    });
    this.$emit("update:error", {
      ...this.error,
      files: this.isErrorFile,
    });
  }

  get isErrorFile(): boolean {
    const { afterPacking, ktp, npwp } = this.internationalDocumentDetail;
    return [afterPacking, afterPacking, ktp, npwp].some(
      (required, index) => required && !this.storageImages[index]
    );
  }

  deleteFiles(index: number) {
    this.storageImages[index] = "";
    this.updateFiles();
  }

  erroMessageDelete(index: number) {
    this.deleteFiles(index);
  }
}
